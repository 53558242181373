<template>
  <div class="register">
    <div class="header"></div>
    <div class="content">
      <div class="container">
        <AppInputForm
          v-model="userAdd.formValid"
          v-slot="{ validate }"
          class="p-3 p-lg-5"
          @validate="validatUser"
        >
          <div class="row">
            <div class="row p-0">
              <div class="col-12">
                <GoBackBtn class="m-0" @click="goBack">Back</GoBackBtn>
                <h1 class="title">SIGN UP WITH POCKETYARD</h1>
                <h2 class="sub-title">We'll need some information from you to get started.</h2>
              </div>
            </div>

            <div class="register-page d-flex justify-content-center flex-column flex-md-row mt-4">
              <div class="w-100 w-md-50 px-md-3">
                <div class="card">
                  <h3 class="mb-3">ACCOUNT INFORMATION</h3>
                  <NewAppInputRadioGroup
                    class="mb-2 mb-md-4"
                    v-model="userAdd.form.persona"
                    :disabled="userAdd.loading"
                    :items="personas"
                    :perRow="2"
                    :validations="[validation.required()]"
                    :validate="validate"
                  />
                  <AppInput
                    v-model="userAdd.form.email"
                    label="EMAIL"
                    placeholder="Enter email address"
                    ref="email_el"
                    :disabled="userAdd.loading"
                    :validate="validate"
                    :validations="[validation.required(), validation.email()]"
                  />
                  <NewAppInputSelect
                    v-model="userAdd.form.company"
                    ref="company_select_el"
                    label="Company"
                    class="w-100 mt-0 mt-md-4"
                    placeholder="Choose Company..."
                    searchPlaceholder="Search Company"
                    name="company"
                    onClickDisabledText="Enter your email first before selecting a company"
                    :forcedErrorText="userAdd.companyError"
                    :items="mappedCompanies"
                    @onSearchChange="(e) => (userAdd.companySearch = e)"
                    @onClickItem="onCompanyClick"
                    :disabled="userAdd.companyDisabled"
                    :loading="userAdd.loading"
                  />
                  <AppInputSelect
                    v-model="userAdd.form.groups"
                    multiple
                    label="Role"
                    class="w-100"
                    :disabled="!userAdd.form.company"
                    onClickDisabledText="Select a company first before selecting a role"
                    :loading="userGroupLoading"
                    :items="userGroups"
                    :validations="[validation.required()]"
                    :validate="validate"
                    :shouldSelectFirstItem="false"
                  ></AppInputSelect>
                </div>
              </div>
              <div class="w-100 w-md-50 px-md-3 mt-3_5 mt-md-0">
                <div class="card">
                  <h3 class="mb-2_5">PERSONAL INFORMATION</h3>
                  <div class="d-flex flex-column">
                    <div class="d-flex flex-column flex-md-row">
                      <div class="w-100 w-md-50">
                        <AppInput
                          v-model="userAdd.form.first_name"
                          label="FIRST NAME"
                          class="w-100"
                          :disabled="userAdd.loading"
                          :validate="validate"
                          :validations="[validation.required()]"
                        />
                      </div>
                      <div class="w-100 w-md-50">
                        <AppInput
                          v-model="userAdd.form.last_name"
                          label="LAST NAME"
                          class="ml-md-2"
                          :disabled="userAdd.loading"
                          :validate="validate"
                          :validations="[validation.required()]"
                        />
                      </div>
                    </div>
                    <div class="w-100">
                      <AppInput
                        v-model="userAdd.form.mobile_number"
                        label="CONTACT NUMBER"
                        :disabled="userAdd.loading"
                        :validate="validate"
                        :validations="[
                          validation.required(),
                          validation.minLength(8),
                          validation.usPhoneNum(),
                        ]"
                      />
                    </div>
                  </div>
                </div>
                <div class="card mt-3_5">
                  <h3 class="mb-3">PASSWORD</h3>
                  <div class="row">
                    <div class="col-12">
                      <AppInput
                        v-model="userAdd.form.password"
                        label="PASSWORD"
                        class="w-100"
                        type="password"
                        :disabled="userAdd.loading"
                        :validate="validate"
                        :validations="[validation.required()]"
                      />
                    </div>
                    <div class="col-12 mt-2">
                      <AppInput
                        v-model="userAdd.form.confirm_password"
                        label="CONFIRM PASSWORD"
                        ref="confirm_password_el"
                        class="w-100"
                        type="password"
                        :disabled="userAdd.loading"
                        :validate="validate"
                        :validations="[
                          validation.required(),
                          validation.confirmed(
                            'Confirm Password',
                            userAdd.form.password,
                            'password'
                          ),
                        ]"
                      />
                    </div>
                  </div>
                </div>
                <AppBtn
                  type="submit"
                  class="mt-4 w-100 sign-up"
                  :class="userAdd.button_text !== 'SIGN UP' ? 'bg-danger' : ''"
                  :loading="userAdd.loading"
                >
                  {{ userAdd.button_text }}
                </AppBtn>
              </div>
            </div>
          </div>
        </AppInputForm>
      </div>
    </div>
    <div class="footer">
      <span>
        Already have an account?
        <router-link to="login">
          <span class="">Log In</span>
        </router-link>
      </span>
    </div>

    <TermsAndConditions
      v-model="userAdd.tosModal"
      @close="handleCloseTos"
    ></TermsAndConditions>
  </div>
</template>

<script>
import AppInputForm from '@/shared/elements/AppInputForm.vue';
import AppInput from '@/shared/elements/AppInput.vue';
import AppBtn from '@/shared/elements/AppBtn.vue';
import TermsAndConditions from '@/components/Partial/Modals/TermsAndConditions.vue';

import GoBackBtn from '@/components/Partial/GoBackBtn.vue';
import AppInputSelect from '@/shared/elements/AppInputSelect.vue';
import NewAppInputSelect from '@/shared/elements/NewAppInputSelect.vue';

import _appApiHelper from '@/shared/mixins/_appApiHelper';
import validation from '@/shared/utils/validation';
import Company from '@/shared/api/Company';
import User from '@/shared/api/Users';
import PersonaConstants from '@/shared/constants/PersonaConstants';

import _ from 'lodash';
import NewAppInputRadioGroup from '../../shared/elements/NewAppInputRadioGroup.vue';

const INITIAL_FORM_STATE = {
  first_name: '',
  last_name: '',
  persona: 'vendor',
  email: '',
  password: '',
  confirm_password: '',
  company: null,
  mobile_number: '',
  groups: [],
  tos: false,
};

export default {
  name: 'Register',

  components: {
    AppInputForm,
    AppInput,
    AppBtn,
    GoBackBtn,
    AppInputSelect,
    NewAppInputSelect,
    NewAppInputRadioGroup,
    TermsAndConditions,
  },

  mixins: [_appApiHelper],

  watch: {
    'userAdd.form.password': function () {
      if (this.userAdd.form.confirm_password) {
        this.$refs.confirm_password_el.validateInput();
      }
    },
    'userAdd.form.email': function (...args) {
      this.debouncedWatch(...args);
    },
    'userAdd.companySearch': function (...args) {
      this.debouncedFetch(...args);
    },
    'userAdd.form.persona': function () {
      this.companies = [];
      this.userAdd.form.company = null;
      this.userAdd.companyDisabled = false;
    },
    'userAdd.form.company': async function () {
      await this.fetchUserGroup();
    },
  },

  data() {
    return {
      validation,
      companies: [],
      personas: [
        {
          title: 'Vendor Account',
          value: 'vendor',
          // description: 'Sell construction equipment or rent truck',
        },
        {
          title: 'Buyer Account',
          value: 'buyer',
          // description:
          //   'Shop construction materials & compare pricing from various seller',
        },
      ],
      userGroups: [],
      userGroupLoading: false,
      userAdd: {
        loading: false,
        modal: false,
        tosModal: false,
        tosAccepted: false,
        success: false,
        confirm: false,
        formValid: false,
        companyDisabled: true,
        companySearch: '',
        companyError: '',
        button_text: 'SIGN UP',
        form: { ...INITIAL_FORM_STATE },
      },
    };
  },

  computed: {
    mappedCompanies() {
      return this.companies.map((c) => ({ ...c, text: c.name, value: c.id }));
    },
  },

  methods: {
    goBack() {
      if (!window) {
        return;
      }

      if (this.url) {
        this.$router.push(this.url);
      } else if (window.history.length > 1) {
        this.$router.back();
      }
    },
    handleCloseTos(accepted) {
      this.userAdd.tosModal = false;
      this.userAdd.tosAccepted = accepted

      if (accepted) {
        this.registerUser();
      }
    },
    validatUser() {
      if (!this.userAdd.formValid) {
        return;
      }

      if (!this.userAdd.form.company) {
        this.userAdd.companyError = 'Company is required';
        return;
      }

      // show modal
      this.userAdd.tosModal = true;
    },
    async registerUser() {
      const form = {
        ...this.userAdd.form,
        company_id: this.userAdd.form.company.id,
        password_confirmation: this.userAdd.form.confirm_password,
      };

      delete form.company;

      this.userAdd.loading = true;

      try {
        await User.registerUser(form);
      } catch (e) {
        this.userAdd.loading = false;
        return;
      }

      this.resetForm();
      this.$router.push({ name: 'RegisterSuccess' });
    },

    resetForm() {
      this.userAdd.form = { ...INITIAL_FORM_STATE };
    },

    async fetchCompanyFromEmailDomain() {
      const isValidEmail = /\S+@\S+\.\S+/.test(this.userAdd.form.email);

      if (!isValidEmail) {
        return;
      }

      this.userAdd.loading = true;
      let res = null;

      try {
        res = await Company.findCompanyByEmailDomain({ search: this.userAdd.form.email, type: this.userAdd.form.persona });
      } catch (e) {
        this.userAdd.loading = false;
        return;
      }

      this.userAdd.loading = false;

      this.companies = [];

      if (res.data) {
        const mappedData = { ...res.data, text: res.data.name, value: res.data.id };
        this.companies = [mappedData];
        this.$nextTick(() => {
          this.$refs.company_select_el.selectItem(mappedData);
        });
        this.userAdd.companyDisabled = true;
      } else {
        this.userAdd.companyDisabled = false;
      }
    },

    async searchCompany() {
      this.userAdd.loading = true;

      const res = await Company.searchCompany({ type: this.userAdd.form.persona, search: this.userAdd.companySearch });

      if (res.status === 200) {
        this.companies = res.data;
      }

      if (this.companies.length <= 0) {
        this.userAdd.companyError = 'Company does not exist';
      }

      this.userAdd.loading = false;
      this.$refs.company_select_el.focusElement();
    },

    onCompanyClick(e) {
      this.userAdd.companyError = '';
      this.userAdd.form.company = e;
    },

    async fetchUserGroup() {
      if (!this.userAdd.form.company) {
        this.userGroups = [];
        return;
      }

      this.userGroupLoading = true;

      const companyType = this.userAdd.form.persona;
      const persona = PersonaConstants[companyType];

      if (persona && persona.group_names) {
        const owner = persona.group_names.find((group) => group.value === 'owner');
        const response = await User.getUserGroups();
        this.userGroups = response.data
          .filter(
            (group) =>
              group.name !== owner.name &&
              persona.group_names.find((constGroup) => constGroup.name === group.name)
          )
          .map((group) => ({
            text: group.name,
            value: group.id,
          }));
      }

      this.userGroupLoading = false;
    },
  },

  created() {
    this.debouncedWatch = _.debounce(() => {
      this.fetchCompanyFromEmailDomain();
    }, 500);

    this.debouncedFetch = _.debounce((newValue) => {
      if (newValue.trim().length >= 3) {
        this.searchCompany();
      }
    }, 1000);
  },

  beforeUnmount() {
    this.debouncedWatch.cancel();
    this.debouncedFetch.cancel();
  },
};
</script>

<style lang="scss" scoped>
@import '@/scss/theme/_inputs';

.register {
  background-color: #f1f1f1;

  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: auto 1fr auto;
  grid-template-areas:
    'header'
    'content'
    'footer';

  min-height: 100%;
}

h1 {
  font-style: normal;
  font-weight: 400;
  font-size: 36px;
  line-height: 52px;
  letter-spacing: 0.8px;
}

h2 {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
}

h3 {
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  display: flex;
  align-items: center;
  letter-spacing: 0.6px;

  color: #777777;
}

.title {
  margin-top: 24px;
  @include media-md('below') {
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
  }
}

.sub-title {
  @include media-md('below') {
    font-size: 14px;
    font-weight: 300;
    line-height: 21px;
    letter-spacing: 0px;
  }
}

.card {
  width: 100%;
  padding: 24px 32px;
  box-shadow: 0px 0px 1px rgba(48, 49, 51, 0.05),
    0px 1px 3px rgba(48, 49, 51, 0.1);
  border-radius: 8px;
}

.header {
  grid-area: header;
}

.container {
  max-width: 1290px;
  padding: 0;
  grid-area: content;
  margin-top: 73px;

  @include media-md('below') {
    margin-top: 0px;
    padding-top: 20px;
    padding-left: 17px;
    padding-right: 17px;
  }
}

.footer {
  grid-area: footer;

  display: flex;
  justify-content: center;
  align-content: center;

  margin-bottom: 43px;
}

.sign-up {
  padding: 14px 32px;
  background: #f38725;
  box-shadow: 0px 0px 1px rgba(48, 49, 51, 0.05),
    0px 1px 3px rgba(48, 49, 51, 0.1);
  border-radius: 2px;

  &:hover {
    background: rgb(201, 113, 31);
  }
}

.mt-3_5 {
  margin-top: 30px;
}

.mb-2_5 {
  margin-bottom: 22px;
}
</style>
