<template>
  <div
    class="app-input"
    :class="{ 'app-input-focus': focus }"
    :style="{ '--input-hover-color': `var(--${color})` }"
  >
    <!-- Label -->
    <label v-if="label" :for="name" class="app-input-label">
      <span>{{ label }}</span>
      <span class="text-danger">*</span>
    </label>

    <div class="position-relative">
      <div class="app-input-container" @click="onClick">
        <!-- Prepend Icon -->
        <div class="app-input-icon-prepend">
          <slot name="prepend-icon">
            <AppIcon v-if="prependIcon" :name="prependIcon"></AppIcon>
          </slot>
        </div>
        <!-- Input Element -->
        <input
          v-if="focus && !disabled"
          ref="searchField"
          type="text"
          v-bind="$attrs"
          v-model="searchFilter"
          :disabled="disabled"
        />
        <!-- Input Element -->
        <input
          v-else
          readonly
          ref="inputField"
          type="text"
          :value="loading ? 'LOADING' : displayText"
          v-bind="$attrs"
          :name="name"
        />
        <!-- Form Validation Input (maintain app-form-validation class) -->
        <input
          class="app-form-validation"
          type="hidden"
          :value="allValidationsPassed"
        />
        <!-- Append Icon -->
        <div v-if="appendIcon" class="app-input-icon-prepend">
          <slot name="append-icon">
            <AppIcon :name="appendIcon"></AppIcon>
          </slot>
        </div>
      </div>
      <!-- Select Options -->
      <transition name="app-input-select-options">
        <ul
          v-show="focus && items.length > 0"
          class="app-input-select-options bg-white shadow-40 w-100"
        >
          <li
            class="app-input-select-option-search bg-white sticky-top"
            @click="preventClose"
          >
            <slot name="option-header"></slot>
          </li>
          <li
            v-for="(item, index) in items"
            tabindex="0"
            :key="`app-input-select-option-${index}-${
              item.value || item.text || item
            }`"
            class="p-1 pl-3 pr-3"
            @click="selectItem(item)"
          >
            <slot name="option-item" :item="item">
              <span class="pt-1 pb-1 d-inline-block">{{
                item.text || item.value || item
              }}</span>
            </slot>
          </li>
          <li
            class="app-input-select-option-footer p-1 bg-white position-sticky"
            @click="preventClose"
          >
            <slot name="option-footer"></slot>
          </li>
        </ul>
      </transition>
    </div>
    <!-- Error Message -->
    <span v-if="!hideError || forcedErrorText" class="app-input-error">
      {{ error.trim() || forcedErrorText }}
    </span>

    <!-- Error Message -->
    <span v-if="showDisabledText" class="app-input-error">
      {{ onClickDisabledText }}
    </span>
  </div>
</template>

<script>
import _appDefaultInput from '@/shared/mixins/_appDefaultInput';
import _appErrorValidation from '@/shared/mixins/_appErrorValidation';

import AppIcon from './AppIcon.vue';

export default {
  name: 'NewAppInputSelect',

  components: { AppIcon },

  mixins: [_appDefaultInput, _appErrorValidation],

  props: {
    value: { type: [String, Number, Array, Object], default: '' },
    items: { type: Array, default: () => [] },
    name: { type: String, default: '' },
    label: { type: String, default: '' },
    color: { type: String, default: 'primary' },

    hideError: { type: Boolean, default: false },
    validate: { type: [Number, String, Boolean], default: false },
    validations: { type: Array, default: () => [] },
    disabled: { default: false },
    loading: { default: false },

    searchPlaceholder: { type: String, default: '' },
    searchValue: { type: String, default: '' },

    prependIcon: { type: String, default: '' },
    inactiveIcon: { type: String, default: 'caret_down' },
    activeIcon: { type: String, default: 'caret_up' },
    appendIcon: { type: String, default: '' },

    forcedErrorText: { type: String, default: '' },
    onClickDisabledText: { type: String, default: '' },
  },

  model: {
    prop: 'innerValue',
    event: 'change',
  },

  data() {
    return {
      focus: false,
      preventCloseFlag: false,
      searchFilter: '',
      displayText: '',
      showDisabledText: false,
    };
  },

  watch: {
    items(value) {
      if (value.length > 0 && !this.disabled && this.shouldSelectFirstItem) {
        this.selectFirstItem();
      }

      if (value.length === 0) {
        this.displayText = '';
      }
    },
    searchFilter: {
      handler(value) {
        this.$emit('onSearchChange', value);
      },
    },
    innerValue: {
      handler(value) {
        if (!this.focus) {
          this.displayText = this.getDisplayText(value);
        }

        if (!value) {
          this.displayText = '';
        }
      },
    },
  },

  methods: {
    getDisplayText(newVal) {
      if (!newVal) {
        return '';
      }

      const value = newVal.value || newVal;
      const displayable = this.items.find(
        (val) => val.value === value || val === value
      );

      if (!displayable) {
        return '';
      }

      return displayable.text || displayable.value || displayable;
    },
    openOptions() {
      this.focus = true;
      this.showDisabledText = false;
      setTimeout(() => {
        document.addEventListener('click', this.closeOptions, false);
      }, 0);
    },
    closeOptions() {
      if (this.preventCloseFlag) {
        this.preventCloseFlag = false;
      } else {
        this.focus = false;

        if (typeof this.innerVal !== 'object') {
          this.displayText = this.searchFilter;
        }

        setTimeout(() => {
          document.removeEventListener('click', this.closeOptions, false);
        }, 0);
      }
    },
    preventClose() {
      this.preventCloseFlag = this.focus;
      // this.focus = this.focus;
    },
    selectItem(item, keyPress = false) {
      if (keyPress) {
        this.closeOptions();
      }

      this.innerVal = item;
      this.$emit('change', item);
      this.$emit('onClickItem', item);
      this.displayText = this.getDisplayText(item);
      this.forceValid();

      this.showDisabledText = false;
    },
    forceValid() {
      this.allValidationsPassed = true;
      this.error = '';
    },
    isSelected(item) {
      if (this.innerVal) {
        const itemVal = item.value || item.text || item;

        if (this.multiple) {
          return this.innerVal.findIndex((val) => val == itemVal) > -1;
        } else {
          return this.innerVal === itemVal;
        }
      }
    },
    removeItem(index) {
      this.preventClose();
      this.innerVal.splice(index, 1);
    },
    selectFirstItem() {
      if (!this.innerVal && this.items.length > 0) {
        const item = this.items[0];
        const newVal = item.value || item.text || item;
        if (this.multiple) {
          if (!this.innerVal) {
            this.innerVal = [newVal];
          } else if (this.innerVal.includes(newVal)) {
            this.innerVal = this.innerVal.filter((item) => item !== newVal);
          } else {
            this.innerVal = [...this.innerVal, newVal];
          }
        } else {
          this.innerVal = newVal;
        }
      }
    },
    focusElement(val = true) {
      this.focus = val;
    },
    onClick() {
      if (this.disabled && this.onClickDisabledText) {
        this.showDisabledText = true;
        return;
      }

      return this.disabled || this.preventCloseFlag
        ? null
        : this.focus
        ? this.closeOptions()
        : this.openOptions();
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/scss/theme/_inputs';
</style>
