<template>
  <div class="radio-group d-flex flex-column flex-md-row">
    <div
      class="radio w-100 w-md-50"
      @click="selectRadio(item)"
      :class="selectedItem && item.value === selectedItem.value ? 'selected' : ''"
      v-for="(item, index) in items"
      :key="index"
    >
      <div class="button-container d-flex justify-content-center align-items-center">
        <div class="button" :class="selectedItem && item.value === selectedItem.value ? 'selected' : ''" />
      </div>
      <div class="text-container">
        <div class="title">{{ item.title }}</div>
        <div v-if="item.description" class="description">{{ item.description }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NewAppInputRadio',
  props: {
    items: {
      type: Array,
      required: true,
    },
    perRow: {
      type: Number,
      default: 2,
    },
    value: {
      type: String,
      required: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  model: {
    prop: 'value',
    event: 'change',
  },
  data() {
    return {
      selectedItem: this.value,
    };
  },
  methods: {
    selectRadio(item) {
      if (this.disabled) {
        return;
      }

      this.selectedItem = item;
      this.$emit('change', item.value);
    },
  },
  mounted() {
    if (this.value) {
      this.selectedItem = this.items.find(a => a.value === this.value) || null;
    }
  },
};
</script>

<style lang="scss" scoped>
@import '@/scss/theme/_breakpoints';
.radio-group {
  .radio {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    margin: 0px 10.5px;
    align-self: stretch;

    cursor: pointer;

    border: 1px solid #CECECE;
    border-radius: 8px;
    padding: 14px 31px 16px 16px;
    transition: 0.2s all linear;

    @include media-md('below') {
      margin: 0px;
      margin-bottom: 16px;
      justify-content: start;
    }

    .button-container {
      margin-right: 17px;
    }

    .button {
      width: 22px;
      height: 22px;

      border-radius: 50%;
      border: 2px solid #B3B3B3;
      transition: 0.2s all linear;
    }

    .title {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;

      color: #282828;
    }

    .description {
      font-style: normal;
      font-weight: 300;
      font-size: 12px;
      line-height: 16px;

      color: rgba(40, 40, 40, 0.6);

      white-space: normal;

      margin-top: 2px;
    }

    .text-container {
      display: flex;
      justify-content: center;
      align-items: center;
      align-content: center;
    }

    &:first-child {
      margin-left: 0px;
    }

    &:last-child {
      margin-right: 0px;
    }

    &.selected {
      border-color: #F38725;

      .button {
        position: relative;

        background-color: #ffffff;
        border-color: #F38725;

        &::before {
          content: ' ';
          width: 13px;
          height: 13px;

          border-radius: 50%;
          background-color: #F38725;
          transition: 0.2s all linear;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }
  }

}
</style>