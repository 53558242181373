<template>
  <AppModal v-model="innerVal" width="60vw">
    <div class="p-4 d-flex flex-column align-items-center justify-content-center">
      <slot>
        <slot name="content">
          <div class="w-100 row justify-content-between">
            <div class="col-auto ml-0 pl-0">
              <AppInputCheckbox
                v-model="accepted"
                :disabled="isCheckDisabled"
                class="mt-1"
                label="I accept the Terms & Conditions"
              ></AppInputCheckbox>
            </div>
            <div class="col-auto pr-0">
              <AppBtn class="d-block btn-gray" @click="innerVal = false">
                <slot name="action-btn-text"><AppIcon name="close"></AppIcon></slot>
              </AppBtn>
            </div>
          </div>
          <div class="tos-content text-left" @scroll="onScroll">
            <h3>Terms of Use</h3>
            <p>Last updated September 2022</p>
            <p>Thank  you  for  visiting  PocketYard.  These  Terms  of  Use  (the  “Terms”  or  the  “Agreement”) constitute a legally-binding agreement made between you, whether personally or on behalf of an entity  (the  “user,”  “you,”  or  “your”)  and  PocketYard’s  parent  company  Moda  Holdings,  LLC (“Pocket  Yard,”  “we,”  “us”  or  “our”),  concerning  your  access  to  and  use  of  our  website <a href="www.pocketyard.com">www.pocketyard.com</a>, applications, products, services, websites, social media pages, profiles or accounts, media forms and/or channels (collectively, the “Site”). You agree that by accessing the Site in any way, you have read, understood, and agree to be bound by all of these Terms.</p>
            <p>IF  YOU  DO  NOT  AGREE  WITH  ALL  OF  THE  TERMS  HEREIN,  THEN  YOU  ARE EXPRESSLY  PROHIBITED  FROM  USING  THE  SITE  AND  YOU  MUST  DISCONTINUE USE IMMEDIATELY.</p>
            <p>By  using  our  Site  and  receiving  services  related  to  the  Site,  you  also  agree  to  be  bound  by  our Privacy Policy and any supplemental terms and conditions or documents that may be posted on or through the Site from time to time. The Privacy Policy and any supplemental policies are hereby expressly incorporated herein by reference. We reserve the right, in our sole discretion, to make changes or modifications to these Terms at any time and for any reason. We will alert you about any changes by updating the “Last Updated” date of these Terms at the top of this section, and you waive  any  right  to  receive  specific  notice  of  each  such  change.  It  is  your  responsibility  to periodically review these Terms to stay informed of updates. You will be subject to, and will be deemed to have been made aware of and to have accepted, the changes in any revised Terms by your continued use of the Site after the date such revised Terms are posted.</p>
            <p>The information provided on the Site is not intended for distribution to or use by any person or entity in any jurisdiction or country where such distribution or use would be contrary to law or regulation or which would subject us to any registration requirement within such jurisdiction or country. Accordingly, those persons who choose to access the Site from other locations do so on their own initiative and are solely responsible for compliance with local laws, if and to the extent local laws are applicable.</p>
            <p>The PocketYard Site aims to streamline the RFQ and procurement process for building materials within the construction industry. This means that we take on the task of locating desired equipment and  services  for  you,  whether  you  are  a  contractor,  super,  foreman,  estimator,  project  manager, owner of a company or another of our customers. How do we make this possible? Through our proprietary  platform  of  hand-selected  vendors.  This  allows  our  customers  to  locate,  shop,  and streamline the ordering process with an ease that many say they are experiencing with us for the first time in the industry.</p>
            <p>The Site is intended for users who are at least 18 years old or otherwise have the permission of their parent(s) or legal guardian(s) to access the Site.</p>
            <h4>1. Indemnity by You</h4>
            <p>You agree to defend, protect, indemnify and hold PocketYard, its parent, subsidiaries, affiliates, officers,  agents,  predecessors  and  successors  in  interest,  and  other  partners  and  employees, harmless from any loss, liability, claim, or demand, including reasonable attorneys’ fees, arising out of or related to:</p>
            <ul>
              <li>your use of the Site or related services;</li>
              <li>a breach of these Terms;</li>
              <li>any third-party claim of infringement of copyright or other intellectual property rights or invasion of privacy arising from your use of the Site, and/or your making available thereof to other users of the Site;</li>
              <li>any activity related to your use of the Site, be it by you or by any other person accessing your account with or without your consent unless such activity was caused by the act or default of PocketYard.</li>
            </ul>
            <h4>2. Prohibited Uses of the Site</h4>
            <p>The Site and services related to the Site are intended for your own or your business’ own use. By using the Site, you agree not to:</p>
            <ul>
              <li>Reproduce, copy, modify in any material manner, or remove PocketYard’s proprietary marks, logos or notices;</li>
              <li>Lease, resell or redistribute any of our services; </li>
              <li>Use the Site in connection with any commercial endeavors; </li>
              <li>Provide access to or give the Site or any part or portion of the Site to a third party;</li>
              <li>Reverse engineer the Site;</li>
              <li>Make the Site available on any file-sharing platform;</li>
              <li>Harm, as determined in our sole discretion, PocketYard, the Site, other users or third parties or their use and/or experience with the Site with, including but not limited to the uploading of malicious software, viruses, spyware, malware, cookies, bugs, keyloggers, or any other materials expressly prohibited;</li>
              <li>Use the Site in connection with any legal matters that are frivolous, immaterial, illegal, lack merit, involve the laws of foreign legal jurisdictions, or have been pursued for an excessive or unreasonable amount of time as determined by us;</li>
              <li>Use the Site in connection with perpetrating, aiding or abetting a crime or fraudulent activity;</li>
              <li>Systematically retrieve data or other content from the Site to create or compile, directly or indirectly, a collection, compilation, database, or directory without written permission from us;</li>
              <li>Make any unauthorized use of the Site, including collecting usernames and/or e-mail addresses of users by electronic or other means for the purpose of sending unsolicited e-mails, or creating user accounts by automated means or under false pretenses;</li>
              <li>Circumvent, disable, or otherwise interfere with security-related features of the Site, including features that prevent or restrict the use or copying of any content or enforce limitations on the use of the Site and/or the content contained therein;</li>
              <li>Trick, defraud, or mislead us and other users, especially in any attempt to learn sensitive account information such as user passwords;</li>
              <li>Make improper use of our support services or submit false reports of abuse or misconduct;</li>
              <li>Use any information obtained from the Site in order to harass, abuse, or harm another person, or make any statement or take any action that is in violation of any user’s civil or other rights;</li>
              <li>Disparage, tarnish, or otherwise harm, in our opinion, us and/or the Site;</li>
              <li>Engage in any automated use of the Site, such as using scripts to send comments or messages, or using any data mining, robots, or similar data gathering and extraction tools;</li>
              <li>Interfere with, disrupt, or create an undue burden on the Site or the networks or servers connected to the Site;</li>
              <li>Attempt to impersonate another user or person or use the username of another user;</li>
              <li>Sell or otherwise transfer your profile on the Site; </li>
              <li>Use the Site or any related services in a manner that violates any applicable law, regulation or this Agreement;</li>
              <li>Permit any third party to use the Site in a manner that violates any applicable law, regulation or this Agreement.</li>
            </ul>
            <h4>3. PocketYard Customer Satisfaction Policy</h4>
            <p>PocketYard strives towards customer satisfaction with the Site and related services we provide. If you purchase any product or service through us and are not satisfied, please contact PocketYard’s Customer Service Team and we will work with you to rectify your experience with us.</p>
            <h4>4. Intellectual Property Rights</h4>
            <p>Except as expressly set forth in these Terms, nothing contained herein shall be construed as conferring any license or right, by implication or otherwise, under any of our copyrights or other intellectual property rights. You agree that this Site and our content is protected by copyrights, trademarks, service marks, patents or other proprietary rights and laws.</p>
            <p>This Site may include various product names, business names, logos, slogans and other marks in which we have a registered trademark interest or other protectable interest (collectively “PocketYard’s Property”). It may also include product names, business names, logos, slogans and other marks in which others have a protectable interest (collectively “Other Materials”). You may not copy, download, display, distribute, modify or sublicense any of PocketYard’s Property or Other Materials on the Site without the prior express written permission of the appropriate owners.</p>
            <h4>5. Limitation of Liability</h4>
            <p>In no event will we be liable for any damages whatsoever, whether direct, indirect, general, special, compensatory, consequential, and/or incidental, arising out of or relating to the Site, or use thereof. Nothing contained in the Site or in any written or oral communications from PocketYard or its employees or agents shall be construed to make any promise, covenant, warranty, or guaranty, all of which are explicitly disclaimed hereby, contrary to the statements and disclaimers contained in this paragraph.</p>
            <p>The content and functionality on the Site are offered “as is” without warranty of any kind, either express or implied, including, without limitation, implied warranties of merchantability, fitness for a particular purpose, title and non-infringement.</p>
            <p>We make no warranties, express or implied, as to the ownership, accuracy, completeness or adequacy of the Site content or the related services therein or that the functionality of the Site will be uninterrupted or error-free or free from virus or third-party attack. You hereby acknowledge that your use of the Site is at your sole risk.</p>
            <p>UNDER NO CIRCUMSTANCES SHALL POCKETYARD, ITS OFFICERS, OWNERS, EMPLOYEES OR AGENTS AND THEIR RESPECTIVE HEIRS, SUCCESSORS AND ASSIGNS BE LIABLE FOR ANY DAMAGES, INCLUDING DIRECT, INCIDENTAL, PUNITIVE, SPECIAL, CONSEQUENTIAL OR EXEMPLARY DAMAGES THAT DIRECTLY OR INDIRECTLY RESULT FROM USE OF, OR INABILITY TO USE, THE SITE OR THE SERVICES OR THE INFORMATION CONTAINED THEREIN, INCLUDING WITHOUT LIMITATION FOR VIRUSES ALLEGED TO HAVE BEEN OBTAINED FROM THE SITE, EVEN IF POCKETYARD HAS BEEN NOTIFIED OF THE POSSIBILITY OF SUCH DAMAGES OR LOSSES AND REGARDLESS OF THE THEORY OF LIABILITY.</p>
            <p>TO THE GREATEST EXTENT PERMITTED BY LAW, YOU AGREE THAT IN NO EVENT WILL POCKETYARD HAVE ANY LIABILITY, CONTINGENT OR OTHERWISE, FOR ANY INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL, OR EXEMPLARY DAMAGES IN ANY WAY ARISING OUT OF OR RELATING TO THE AGREEMENT, THE SITE OR RELATED SERVICES, INCLUDING, BUT NOT LIMITED TO: LOST PROFITS, LOST DATA, LOSS OF GOODWILL, COPYRIGHT INFRINGEMENT, WORK STOPPAGE, EQUIPMENT FAILURE OR MALFUNCTION, PERSONAL INJURY, PROPERTY DAMAGE, OR ANY OTHER DAMAGES OR LOSSES, EVEN IF POCKETYARD HAS BEEN ADVISED OF THE POSSIBILITY THEREOF, AND REGARDLESS OF THE LEGAL OR EQUITABLE THEORY (CONTRACT, TORT, STATUTE, INDEMNITY OR OTHERWISE) UPON WHICH SUCH LIABILITY IS BASED.</p>
            <p>In the event that any of the foregoing limitations are deemed to be unenforceable, to the greatest extent permitted by law, you agree that the entire aggregate liability of PocketYard and sole remedy available to any user in any case in any way arising out of or relating to the Terms, the Site or related services shall be limited to monetary damages that in the aggregate may not exceed the greater of $250.00 or the sum of any amount paid by the user to PocketYard during the six (6) months prior to notice to PocketYard of the dispute for which the remedy is sought, whichever amount is lesser.</p>
            <h4>6. User Submitted Content</h4>
            <p>The Site may allow business and customer users to post images, audio, text, video, links to other sites, or other content to the Site (collectively “User Content”). Neither PocketYard nor any of its affiliates endorses these entities or individuals nor are we in any way associated with any of the materials that they may post on or through the Site. Neither PocketYard, its parent, subsidiaries, affiliates, officers, agents, predecessors and successors in interest, and other partners shall have any responsibility or liability of any nature whatsoever arising in connection with any materials provided by these entities or individuals or in connection with any conduct of these entities or individuals.</p>
            <p>Each user who posts any User Content acknowledges and agrees that we have the right, in our sole discretion, to take down or remove or alter any and all User Content we deem inappropriate, illegal or otherwise offensive. Any user who posts any User Content hereby represents that such User Content does not violate any third-party intellectual property rights and further grants us a license to show and make this content accessible on the Site or any successor or affiliate app or website. By posting any User Content to the Site, you hereby agree to indemnify us from any damages and claims related to the User Content, including but not limited to any infringement, misappropriation, libel or other claims.</p>
            <h4>7. Site Management</h4>
            <p>We reserve the right, but are not under any obligation, to: (1) monitor the Site for violations of these Terms; (2) take appropriate legal action against anyone who, in our sole discretion, violates the law or these Terms, including without limitation, reporting such individual to law enforcement authorities; (3) in our sole discretion and without limitation, refuse, restrict access to, limit the availability of, or disable (to the extent technologically feasible) any of your contributions or any portion thereof; (4) in our sole discretion and without limitation, notice, or liability, to remove from the Site or otherwise disable all files and content that are excessive in size or are in any way burdensome to our systems; and (5) otherwise manage the Site in a manner designed to protect our rights and property and to facilitate the proper functioning of the Site.</p>
            <h4>8. Modification and Interruptions</h4>
            <p>We reserve the right to change, modify, or remove the content on the Site at any time or for any reason at our sole discretion without notice. However, we have no obligation to update any information on our Site. We also reserve the right to modify or discontinue all or part of the Site without notice at any time. We will not be liable to you or any third party for any modification, price change, suspension, or discontinuance of the Site.</p>
            <p>We cannot guarantee the Site will be available at all times. We may experience hardware, software, or other problems or need to perform maintenance related to the Site, resulting in interruptions, delays, or errors. We reserve the right to change, revise, update, suspend, discontinue, or otherwise modify the Site at any time or for any reason without notice to you. You agree that we have no liability whatsoever for any loss, damage, or inconvenience caused by your inability to access or use the Site during any downtime or discontinuance of the Site. Nothing in these Terms will be construed to obligate us to maintain and support the Site or to supply any corrections, updates, or releases in connection therewith.</p>
            <h4>9. Account Security</h4>
            <p>You are responsible for maintaining the confidentiality of the username and password that you designate during the registration process, and you are fully responsible for all activities that occur under your username and password. You agree to: (1) immediately notify PocketYard of any unauthorized use of your username or password or any other breach of security, and (2) ensure that you exit from your account at the end of each session. PocketYard will not be liable for any loss or damage arising from your failure to comply with this provision. You should use particular caution when accessing your account from a public or shared device so that others are not able to view or record your password or other personal information. If you share your device with others, you may wish to consider disabling your auto-sign in feature if you have it linked to your PocketYard account.</p>
            <h4>10. Electronic Communications, Transactions, and Signatures</h4>
            <p>Accessing and using the Site, sending us e-mails or chat messages, and filling in requested information constitute electronic communications. You consent to receive electronic communications, and you agree that all agreements, notices, disclosures, and other communications we provide to you electronically, via e-mail and on the Site, satisfy any legal requirement that such communications be in writing. You hereby agree to the use of electronic signatures, contracts, orders, and other records, and to electronic delivery of notices, policies, and records of transactions initiated or completed by us or via the Site. You hereby waive any rights or requirements under any statutes, regulations, rules, ordinances, or other laws in any jurisdiction which require an original signature or delivery or retention of non-electronic records, or to payments or the granting of credits by any means other than electronic means.</p>
            <h4>11. Availability Outside the U.S.</h4>
            <p>If you access the Site and its products from outside of the United States, you do so at your own risk and are responsible for compliance with the laws of your jurisdiction. If you access the Site from outside of the United States, you acknowledge that any personal information you provide will be processed in the United States and other geographies as selected by us in our sole discretion, and you hereby consent to the collection and processing of your personal information in a manner consistent with these Terms and the Privacy Policy.</p>
            <h4>12. Term and Termination</h4>
            <p>These Terms shall remain in full force and effect while you use the Site. Without limiting any other provision of these Terms, we reserve the right to, in our sole discretion and without notice or liability, deny access to and use of the Site (including blocking access), to any person for any reason or for no reason, including without limitation for breach of any representation, warranty, or covenant contained in these terms or any applicable law of regulation. We may terminate your use of or participation in the Site or delete your account and any content or information that you posted at any time, without warning, at our sole discretion.</p>
            <p>If we terminate or suspend your account for any reason, you are prohibited from registering and creating a new account under your name, a fake or borrowed name, or the name of any third party, even if you may be acting on behalf of the third party. In addition to terminating or suspending your account, we reserve the right to take appropriate legal action, including without limitation pursuing civil, criminal, and injunctive redress.</p>
            <h4>13. Severability Waiver</h4>
            <p>If any provision of these Terms is found to be invalid by any court having competent jurisdiction, the invalidity of such provision shall not affect the validity of the remaining provisions of these Terms, which shall remain in full force and effect. No waiver of any term shall be deemed a further or continuing waiver of such term or any other term. In addition, our failure to enforce any term shall not be deemed as a waiver of such term or otherwise affect our ability to enforce such term at any point in the future.</p>
            <h4>14. Governing Law</h4>
            <p>These Terms and our legal obligations hereunder are subject to the laws of the State of New York, United States of America, regardless of your location. You hereby consent to the exclusive jurisdiction of and venue in the federal and state courts located in New York, United States of America, in all disputes arising out of or relating to the Site. </p>
            <h4>15. Attorney Fees</h4>
            <p>In the event that PocketYard is successful in whole or in part in any action or proceeding related to or arising from this Agreement, you shall be responsible for PocketYard’s attorneys’ fees and costs.</p>
            <h4>16. Miscellaneous</h4>
            <p>These Terms and any policies or operating rules posted by us on the Site or in respect to the Site constitute the entire agreement and understanding between you and us. Our failure to exercise or enforce any right or provision of these Terms shall not operate as a waiver of such right or provision. These Terms operate to the fullest extent permissible by law. We may assign any or all of our rights and obligations to others at any time. We shall not be responsible or liable for any loss, damage, delay, or failure to act caused by any cause beyond our reasonable control. There is no joint venture, partnership, employment or agency relationship created between you and us as a result of these Terms or use of the Site. You agree that these Terms will not be construed against us by virtue of having drafted them. You hereby waive any and all defenses you may have based on the electronic form of these Terms and the lack of signing by the parties hereto to execute these Terms.</p>
            <h4>17. Please Read Our Privacy Policy</h4>
            <h4>18. Contact</h4>
            <p>For questions or concerns, we can be contacted either by mail at: </p>
            <p class="mb-0">Moda Holdings, LLC</p>
            <p class="mb-0">31-77 37<sup>th</sup> Street Apt. 1 B</p>
            <p>Astoria, New York 11103</p>
            <p>Or by email at <a href="mailto:jpmoda@pocketyard.com">jpmoda@pocketyard.com</a></p>

            <h3>Privacy Policy</h3>
            <p>Last updated September 2022</p>
            <p>PocketYard, its parent company Moda Holdings, LLC, and its affiliates and subsidiaries, meaning companies related by common ownership or control (collectively, “PocketYard,” “we,” ”us,” “our”) respect your (the “user,” “you” or “your”) right to privacy and strive to maintain the confidentiality of your personal information. This Privacy Policy describes in greater detail our privacy practices for collecting and maintaining certain information collected about you. This Privacy Policy applies to all versions of our website <a href="www.pocketyard.com">www.pocketyard.com</a>, applications, products, services, websites, social media pages, profiles or accounts, media forms and/or channels (collectively, the “Site”).</p>
            <p>We may change this Privacy Policy from time to time. If we make changes, we will notify you by revising the date at the top of the page. We require you to read and agree to this Privacy Policy when you visit and access the Site. </p>
            <p>You agree that by accessing the Site in any way, you have read, understood, and agree to be bound by all of this Privacy Policy. </p>
            <h4>1. Application of this Privacy Policy</h4>
            <p>This Privacy Policy (the “Privacy Policy”) applies to our Site, products, and related services whether or not they link to this Privacy Policy. PocketYard is the responsible entity for all Personally Identifiable Information (“PII”) subject to this Privacy Policy for any products or services offered or provided to users anywhere in the world.</p>
            <h4>2. Information We Collect</h4>
            <p>Individual and businesses users make up the community we serve. We may collect some limited information from either through the following channels: </p>
            <ul>
              <li>We may ask our users for information such as phone number, e-mail address, name and passwords in order to create an account. </li>
              <li>We maintain a helpdesk function for addressing any questions, comments or concerns about the Site. We may access users’ e-mail addresses and any other information they elect to provide as part of responding to such inquiries. This information is only utilized for user support, is stored only for recordkeeping and analytics purposes, and is not otherwise shared. </li>
              <li>We may from time to time collect automatically-collected information when you access our services (“Usage Data”), such as browser information, link interaction with our products, Internet Protocol (“IP”) address, and any other usage information collected from cookies and other tracking tools. </li>
              <li>We may also, when you enable location-based services, collect Global Positioning System (GPS) location data and/or motion data. </li>
            </ul>
            <h4>3. Our Cookie Policy</h4>
            <p>Our Site may from time to time use “Cookies.” A Cookie is a small piece of data or text file stored in your computer or mobile device by your web browser. Cookies may contain text that can be read by a server that delivered the Cookie to you. The text contained in the Cookie generally consists of a sequence of letters and numbers that uniquely identifies your computer or mobile device; however, it may contain other information as well.</p>
            <p>By agreeing to accept our use of Cookies, you are giving us permission to place some or all the Cookies described below on your device.</p>
            <h4>4. We may use Cookies for:</h4>
            <ul>
              <li>Identifying the areas of our Site that you have visited; </li>
              <li>Signing in and identifying you as a one of our users; </li>
              <li>Site analytics; </li>
              <li>Remarketing our products or services to you; </li>
              <li>Navigating our Site; and</li>
              <li>Remembering your preferences and settings; or </li>
              <li>Personalizing the content that you see on our Site. </li>
            </ul>
            <p>Most web browsers can be set to disable the use of Cookies. However, if you disable Cookies, you may not be able to access features on our Site correctly or at all. For more information about Cookies, visit <a href="www.aboutcookies.org.">www.AboutCookies.org.</a></p>
            <h4>5. Information Disclosure</h4>
            <p>We limit any sharing of information we have collected about you to certain narrow purposes. Your information may be shared for the following reasons only:</p>
            <p><b>TO COMPLY WITH LAWS OR TO PROTECT RIGHTS OF USERS</b></p>
            <p>If we believe the release of information about you is necessary to respond to a legal process, to investigate or remedy potential violations of our policies, or to protect the rights, property, or safety of others, we may share your information as permitted or required by any applicable law, rule, or regulation. This includes exchanging information with other entities for fraud protection and credit risk reduction.</p>
            <p><b>TO ASSIST THIRD-PARTY SERVICE PROVIDERS</b></p>
            <p>We may share your information with third parties that perform services for us or on our behalf, including payment processing, credit checks, data analysis, e-mail delivery, hosting services, user services, and marketing assistance.</p>
            <p><b>TO TRANSFER ASSETS IN THE EVENT OF SALE OR BANKRUPTCY</b></p>
            <p>If we reorganize or sell all or a portion of our assets, undergo a merger, or are acquired by another entity, we may transfer your information to the successor entity. If we go out of business or enter bankruptcy, your information would be an asset transferred or acquired by a third party. You acknowledge that such transfers may occur and that the transferee may decline to honor the commitments we’ve made in this Privacy Policy.</p>
            <p><b>TO UTILIZE API INTEGRATION WITH SOCIAL MEDIA PROVIDERS </b></p>
            <p>Our interaction with third parties is highly limited as set forth in this Privacy Policy. From time to time, we may integrate or otherwise utilize API for social media through the Site, but all users will be provided the opportunity to “opt in” or “opt out” of this function. </p>
            <h4>5. Our Stance on Contact with You</h4>
            <p>We may from time to time contact you to remind you about something related to the Site or to inform you of other great services we have in the works through “push notifications” or similar means. If you do not wish to receive notifications, you will be provided an “opt-out” option. </p>
            <p>We may also contact our users in other ways such as e-mails or e-newsletters with promotional materials, coupons or deals. You will be provided an “opt-out” option if you do not wish to take advantage of these communications. </p>
            <h4>6. Your Rights in Connection with Information that We Collect about You</h4>
            <p>You have the following rights with regard to information about you:</p>
            <ul>
              <li>The right to be informed about what kind of information about you is collected, stored, processed and disclosed by us; therefore, we have compiled this Privacy Policy for you. </li>
              <li>The right of access (you can request us to provide you verbally or in writing with the type of information we store about you and we have up to one month to respond to your request). </li>
              <li>The right to rectify any Personally Identifiable Information that is inaccurate.</li>
              <li>The right to erasure (some conditions apply, see the Data Retention Section, below). </li>
              <li>The right to restrict processing of your Personally Identifiable Information, however, if you restrict us from processing a part of your PII that is essential to our provision of services, you may be asked to terminate your account and stop using our services. </li>
              <li>The right to data portability; which allows users of our Site to obtain and reuse their Personally Identifiable Information for their own purposes as they see fit, including across different other services; you may request us to transmit your PII directly from our servers to another company’s servers and we will do so, where it is technically feasible. </li>
              <li>The right to object; you may express your objection verbally or in writing and we have up to one month to respond to any such objection; we might may still continue processing your Personally Identifiable Information if we are able to show that we have a compelling reason for doing so. </li>
              <li>The right not to be subject to a decision based solely on automated processing, including profiling, which produces legal effects concerning you or that affects you significantly.</li>
            </ul>
            <p>If you would like to exercise any of the above rights or have any questions about your rights, please contact us with the information provided below.</p>
            <h4>7. Data Retention</h4>
            <p>Your Personally Identifiable Information will be erased when: (i) it is no longer needed for its original processing purpose; (ii) you withdraw your consent for us to store your information by deleting your account; (iii) there is no justified reason for the processing of your Personally Identifiable Information and you object to our processing of your PII; or (iv) erasure of your Personally Identifiable Information is required in order to fulfill a statutory obligation under the EU, US, or state law. Under any of the above-mentioned circumstances, we will make sure your Personally Identifiable Information is erased. You may request us to erase your Personally Identifiable Information verbally or in writing and we have up to one month to respond to any such request.</p>
            <h4>8. How We Keep Your Personal Information Safe</h4>
            <p>We use industry-leading administrative, technical, and physical security measures to help protect your information, including but not limited to storing user information in a secure database. While we have taken reasonable steps to secure the information you provide to us, please be aware that despite our efforts, no security measures are perfect or impenetrable, and no method of data transmission can be completely guaranteed against any interception or other type of misuse. Any information disclosed online is thus vulnerable to interception and misuse by unauthorized parties</p>
            <h4>9. Policy Regarding Children</h4>
            <p>We do not knowingly solicit information from or market to children under the age of 18 unless the children have been granted permission by their parents to use our Site. If you become aware of any data that we have collected from children under age 18, please contact us using the contact information provided below.</p>
            <h4>10. General Compliance with Laws</h4>
            <p>Our Privacy Policy complies with the strictest laws on handling of Personally Identifiable Information. Such laws include: the USA’s Children's Online Privacy Protection Act (COPPA), the European Union’s General Data Protection Regulation (GDPR), and the California Consumer Privacy Act (CCPA).</p>
            <h4>11. Contact</h4>
            <p>For questions or concerns, we can be contacted either by mail at: </p>
            <p class="mb-0">Moda Holdings, LLC</p>
            <p class="mb-0">31-77 37th Street Apt. 1 B</p>
            <p>Astoria, New York 11103</p>
            <p>Or by email at <a href="mailto:jpmoda@pocketyard.com">jpmoda@pocketyard.com</a></p>
          </div>
        </slot>
        <!-- Action Buttons -->
        <slot name="action-btn ">
          <div class="ml-auto mt-2">
            <!-- Confirm Action Buttons -->
            <AppBtn class="d-block" @click="onAgree" :disabled="!accepted">
              <slot name="action-btn-text">I Agree</slot>
            </AppBtn>
          </div>
        </slot>
      </slot>
    </div>
  </AppModal>
</template>

<script>
import _appDefaultInput from '@/shared/mixins/_appDefaultInput';
import AppModal from '@/shared/elements/AppModal.vue';
import AppIcon from '@/shared/elements/AppIcon.vue';
import AppBtn from '@/shared/elements/AppBtn.vue';
import AppInputCheckbox from '../../../shared/elements/AppInputCheckbox.vue';

export default {
  name: 'ActionModal',

  components: { AppModal, AppIcon, AppBtn, AppInputCheckbox },

  mixins: [_appDefaultInput],

  props: {
    withDescription: { type: [Boolean], default: true },
  },
  data() {
    return {
      accepted: false,
      isCheckDisabled: true,
    }
  },

  watch: {

  },

  methods: {
    onAgree() {
      this.$emit('close', true)
    },
    onScroll({ target: { scrollTop, clientHeight, scrollHeight } }) {
      if ((scrollTop + clientHeight) >= (scrollHeight - 200)) {
        this.isCheckDisabled = false
      }
    }
  }
};
</script>

<style lang="scss" scoped>
  .tos-content {
    max-height: 60vh;
    overflow: auto;
  }
</style>
